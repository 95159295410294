<template>
  <div class="form-group mb-0">
    <label :for="title" class="filter-label">Cycle</label>
    <select
      :id="title"
      class="form-control selectpicker"
      data-display="static"
      v-model="selected"
      @change="changeCycle"
      tabindex="-98"
    >
      <option
        v-for="cycle in this.cycles"
        class="cycle-option"
        :data-content="displayCycleContent(cycle)"
        :key="cycle.campaign_cycle_id"
        :value="cycle.campaign_cycle_id"
      >
        {{ moment(cycle.start_date) }} to {{ moment(cycle.end_date) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectCycle',
  props: {
    cycles: {
      type: Array
    },
    cycleId: {
      type: [String, null],
      required: true
    }
  },
  data() {
    return {
      title: 'cycle-select',
      selected: this.cycleId,
    }
  },
  watch: {
    cycleId: {
      async handler(newVal, oldVal) {
        if ($(`#${this.title} option[value="N/A"]`).length) {
          $(`#${this.title} option[value="N/A"]`).remove();
        }
        if (!newVal) {
          if (!$(`#${this.title} option[value="N/A"]`).length) {
              $(`#${this.title}`).append($('<option>', {
                  value: 'N/A',
                  'data-content': 'N/A'
              }));
          }

          this.selected = 'N/A';
          $(`#${this.title}`).val('N/A');
          this.refreshSelectForm();
        }
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    this.refreshSelectForm();
  },
  methods: {
    refreshSelectForm() {
      $(`#${this.title}`).selectpicker({
        style: '',
        styleBase: 'form-control',
        size: 6,
        dropupAuto: false,
      });
      $(`#${this.title}`).selectpicker('refresh');
    },
    moment(date) {
      return moment(date).format('L');
    },
    cycleStatus(statusId) {
      return statusId === 2 || statusId === 4;
    },
    showDaysLeft(daysLeft) {
      return daysLeft > 1;
    },
    changeCycle() {
      this.$emit('change-cycle', this.selected);
    },
    displayCycleContent(cycle) {
      const name = document.createElement('span');

      name.setAttribute('class', 'select-cycle-name');
      name.textContent = `${this.moment(cycle.start_date)} to ${this.moment(cycle.end_date)}`;

      if (!this.cycleStatus(cycle.status_id)) return `${name.outerHTML}`;

      const status = document.createElement('span');
      const subText = document.createElement('span');

      status.setAttribute('class', 'select-cycle-status status-label active-status mr-1 float-xl-right');
      status.textContent = 'Current';

      subText.setAttribute('class', 'remaining-days-text');

      if (this.showDaysLeft(cycle.days_left)) {
        subText.textContent = `${cycle.days_left} Days Remaining`;
      } else {
        subText.textContent = 'Ends Today';
      }

      return `${name.outerHTML} ${subText.outerHTML} ${status.outerHTML}`;
    }
  }
};

</script>

<style>
.select-cycle-name {
  font-size: 14px;
  color: #3F4254;
  line-height: 22px;
}

.remaining-days-text {
  font-size: 12px;
  color: #B5B5C3;
  line-height: 22px;
}

.dropdown-menu .cycle-option {
  padding-left: 12px;
  padding-right: 8px;
}

@media screen and (max-width: 1400px) {
  .select-cycle-status {
    display: none;
  }
}
</style>
