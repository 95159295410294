import { createRouter, createWebHistory } from 'vue-router'
import CampaignDashboard from '../pages/Campaign-Dashboard/App.vue'
import Singles from '../pages/Singles-Dashboard/App.vue'
import Scheduled from '../pages/Scheduled/App.vue'
import SelectJobs from '../pages/Select-Jobs/App.vue'

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: CampaignDashboard
  },
  {
    path: '/singles',
    name: 'Singles',
    component: Singles
  },
  {
    path: '/select-jobs',
    name: 'Select Jobs',
    component: SelectJobs
  },
  {
    path: '/sell-info',
    name: 'Scheduled',
    component: Scheduled
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router