<template>
  <div class="form-group mb-0">
    <label :for="title" class="filter-label">Date Range</label>
    <div class="input-group">
      <input v-if="dateRangeType === 0" :id="title" class="form-control" autocomplete="off" aria-describedby="date-range-button">
      <input v-else-if="dateRangeType === 1" :id="title" class="form-control" autocomplete="off" aria-describedby="date-range-button">
      <div class="input-group-append">
        <button id="date-range-button" data-jt-ref="daterangepickerbutton" class="btn btn-primary" type="button">
          <i aria-hidden="true" class="fal fa-calendar-alt"></i>
          <span class="sr-only">Open calendar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:'DateRangePicker',
  emits: ['select-date-range', 'date-range-exceed-warning'],
  props: {
    singleDate: {
      type: Object
    },
    dateRangeType: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      title: 'date-range-picker',
      newStartDate: null,
      newEndDate: null,
    }
  },
  mounted() {
    const self = this;

    if (self.dateRangeType === 0) {
      $(`#${self.title}`).daterangepicker({
        opens: 'left',
        autoUpdateInput: false,
        minDate: moment().subtract(1, 'years'),
        maxDate: moment(),
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        showCustomRangeLabel: true,
        alwaysShowCalendars: true,
      });
    } else if (self.dateRangeType === 1) {
      $(`#${self.title}`).daterangepicker({
        opens: 'left',
        startDate: moment(self.singleDate.startDate),
        endDate: moment(self.singleDate.endDate),
        minDate: moment().subtract(1, 'years'),
        maxDate: moment(),
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        showCustomRangeLabel: true,
        alwaysShowCalendars: true,
      });
    }

    $('[data-jt-ref="daterangepickerbutton"]').click(() => {
        $(`#${self.title}`).data('daterangepicker').show();
    });

    $(`#${self.title}`).on('apply.daterangepicker', function(ev, picker) {

      const startDate = picker.startDate;
      const endDate = picker.endDate;
      const maxAllowedEndDate = moment(startDate).add(60, 'days');

      if (endDate.isAfter(maxAllowedEndDate)) {
        self.$emit('date-range-exceed-warning');
        return
      }
      
      self.newStartDate = startDate.format('YYYY-MM-DD');
      self.newEndDate = endDate.format('YYYY-MM-DD');

      $(`#${self.title}`).val(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`);

      self.$emit('select-date-range', { newStartDate: self.newStartDate, newEndDate: self.newEndDate });
    });
  },
};
</script>

<style>
.daterangepicker {
  margin-right: -37px;
}
</style>
