<template>
	<div class="col-12">
		<div class="alert alert-success d-flex align-items-center justify-content-between mb-0 pt-2 pb-2">
			<i class="fal fa-check-circle text-2"></i>
			<p class="mb-0 w-100 pl-3">
        Success! Your budget has been updated to {{newBudgetFormatted}} for
        <span v-if="updatedBudgetData.currentCycleChanged"> the current cycle</span>
        <span v-if="updatedBudgetData.currentCycleChanged && updatedBudgetData.futureCyclesChanged"> and</span>
        <span v-if="updatedBudgetData.futureCyclesChanged"> future cycles</span>.
				<span v-if="updatedBudgetData.resumeCycle"> Job postings will resume within one business day.</span>
      </p>
			<button type="button" class="btn btn-transparent text-success close" data-dismiss="alert">
				<i class="fal fa-times"></i>
			</button>
		</div>
  </div>
</template>

<script>

export default {
  name: 'UpdateBudgetSuccess',
	props: {
		updatedBudgetData: {
			type: Object
		}
	},
	data() {
		return {
			newBudgetFormatted: null
		}
	},
	watch: {
    updatedBudgetData: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.newBudgetFormatted = numeral(this.updatedBudgetData.newBudget).format('$0,0.00');
      },
      immediate: true,
      deep: true,
    }
	}
}
</script>

<style scoped>
</style>