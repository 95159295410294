<template>
  <div class="form-group mb-0">
    <label :for="title" class="filter-label">Campaign</label>
    <select
      :id="title"
      class="form-control selectpicker"
      data-display="static"
      data-live-search-placeholder="Search Campaign"
      :data-live-search="showSearchCampaigns"
      v-model="selected"
      @change="changeCampaign"
      tabindex="-98"
      >
        <option
          v-for="campaign in campaigns"
          :data-content="displayCampaignContent(campaign)"
          :key="campaign.id"
          :value="campaign.id"
        >
          {{ campaign.campaign_name }}
        </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectCampaign',
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      title: 'campaign-select',
      selected: this.campaignId,
    }
  },
  computed: {
    showSearchCampaigns() {
      return this.campaigns.length > 5;
    }
  },
  mounted() {
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    $(`#${this.title}`).selectpicker({
        style: '',
        styleBase: 'form-control',
        size: 6,
        dropupAuto: false,
    });
    $('.filter-option .division-text').hide();
  },
  methods: {
    changeCampaign() {
      this.$emit('change-campaign', this.selected);
    },
    displayCampaignContent(campaign) {
      const name = document.createElement('span');
      const status = document.createElement('span');
      const subText = document.createElement('p');

      name.setAttribute('class', 'select-campaign-name');
      name.textContent = campaign.campaign_name;

      subText.setAttribute('class', 'division-text');
      subText.textContent = `${campaign.divisions_text}`;
      
      if (campaign.status_id === 2 || campaign.status_id === 4) {
        status.setAttribute('class', 'status-label active-status mr-1 float-xl-right');
        status.textContent = 'Active';
      } else {
        status.setAttribute('class', 'status-label inactive-status mr-1 float-xl-right');
        status.textContent = 'Inactive';
      }

      return `${name.outerHTML} ${status.outerHTML} ${subText.outerHTML}`;
    }
  }
};

</script>

<style>
.select-campaign-name {
  font-size: 14px;
  color: #3F4254;
  line-height: 22px;
}

.division-text {
  font-size: 12px;
  color: #6C757D;
  line-height: 18px;
  margin-bottom: 0;
}
</style>
