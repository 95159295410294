<template>
  <div class="modal fade" id="no-active-postings-modal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">There is no traffic within the date range</h5>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close" @click="hideModal">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <p>You have no active postings within the selected date range spanning the last {{ displayDaysWithoutActivePostings }} day{{ displayDaysWithoutActivePostings > 1 ? 's' : '' }}, please expand the date range to view historical data.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoActivePostingsModal',
  props: {
    startDate: String,
    endDate: String,
  },
  computed: {
    daysWithoutActivePostings() {
      const startDate = moment(this.startDate);
      const endDate = moment(this.endDate);
      const daysDifference = endDate.diff(startDate, 'days') + 1;
      return daysDifference;
    },
    displayDaysWithoutActivePostings() {
      return this.daysWithoutActivePostings > 1 ? this.daysWithoutActivePostings : 1;
    },
  },
  mounted() {
    $('#no-active-postings-modal').on('hide.bs.modal');
  },
  methods: {
    hideModal() {
      $('#no-active-postings-modal').modal('hide');
    },
  },
}
</script>

<style scoped>
</style>
