<template>
  <div class="col-14 mb-4">
    <div v-if="showAlert" class="alert alert-warning d-flex align-items-center justify-content-between mb-0 pt-2 pb-2">
      <i class="fal fa-exclamation-triangle text-2"></i>
      <p class="mb-0 w-100 pl-4">Custom date range must not be exceeded to 60 days</p>
      <button type="button" class="btn btn-transparent text-warning close" data-dismiss="alert">
        <i class="fal fa-times text-2"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateRangePickerWarning',
  data() {
    return {
      showAlert: true
    };
  },
};
</script>

<style>
</style>
