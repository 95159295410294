<template>
  <div class="modal fade" id="contact-relationship-manager-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 v-if="campaignContacts" class="modal-title">Contact Relationship Manager ({{campaignContacts.relationship_manager.displayName}})</h4>
          <h4  v-else class="modal-title">Contact Relationship Manager</h4>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div v-if="!renderLoading && !renderSuccess && !renderAPIError" class="modal-body">
          <form>

            <div class="form-group">
              <div class="form-row mb-3">
                <div class="col-8">
                  <h5 class="mb-0">Email Address</h5>
                  <small>Select an email address at which to receive a response.</small>
                </div>
                <div class="col-4">
                </div>
              </div>

              <div class="form-row mb-2">
                <div class="col-auto d-flex align-items-center">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="emailType" id="user-email" value="userEmail" v-model="emailSelected" checked="true">
                      <span></span>
                      
                    </label>
                  </div>
                </div>
                <div class="col-7">
                  {{userEmail}}
                </div>
                <div class="col-4">
                  <h6>Phone Number (optional)</h6>
                </div>
              </div>
              <div class="form-row">
                <div class="col-auto d-flex align-items-center">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="emailType" id="alt-email-selected" value="alternateEmail" v-model="emailSelected">
                      <span></span>
                    </label>
                  </div>
                </div>
                <div class="col-7">
                  <input type="text" class="form-control" autocomplete="no" id="alt-email" placeholder="Enter Email Address" v-model="alternateEmail">
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" autocomplete="no" id="contact-number" placeholder="(000) 123-4567" v-model="contactNumber">
                </div>
              </div>
              <small v-if="errors.invalidEmail" class="text-danger">Please enter a valid email address.</small>
            </div>
            
            <div class="form-row mt-3 mb-3">
              <div class="col-12">
                <div class="form-group">
                  <h5 class="mb-0">Message</h5>
                  <small v-if="errors.characterLimit" class="text-danger">Characters remaining: {{charactersRemaining}}</small>
                  <small v-else>Characters remaining: {{charactersRemaining}}</small>
                  <label for="messageTextArea">

                  </label>
                  <textarea class="form-control rounded-0" id="messageTextArea" rows="5" v-model="message" @keyup="updateCharactersRemaining"></textarea>
                  <small v-if="errors.missingMessage" class="text-danger">Please enter a message.</small>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <h5 class="mb-0">Attach a Screenshot (optional)</h5>
              
              <small>Max Size: {{maxFileSize / 1000}} KB.</small>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" class="custom-file-input pointer" ref="attachFile" id="screnshotInput" @change="onFileSelected">
                  <label class="custom-file-label" for="screnshotInput" aria-describedby="inputGroupFileAddon02">{{image ? image.name : 'Choose file (.jpg or .png)'}}</label>
                </div>
                <button v-if="image" type="button" class="btn btn-link" @click="onFileRemoved">Remove File</button>
              </div>
              <small v-if="errors.invalidFileType" class="text-danger">Invalid file type (.jpg or .png)</small>
              <small v-else-if="errors.invalidFileSize" class="text-danger">File size ({{formattedFileSize}} KB) exceeds limit</small>
              
            </div>
            

          </form>
        </div>
        <div v-if="!renderLoading && !renderSuccess && !renderAPIError" class="modal-footer">
          <button type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-primary" @click="prepareMessage">Send</button>
        </div>


        <div v-if="renderLoading" class="modal-body">
          <div class="d-flex justify-content-center align-items-center">
            <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else-if="renderSuccess" class="modal-body">
          <img id="message-sent" src="../assets/svg/message-sent.svg" alt="Message Sent"/>
          <h3 class="modal-title text-center">Message Sent!</h3>
          <p class="text-center">Your Relationship Manager will reach out to you shortly.</p>
        </div>
        <div v-else-if="renderAPIError" class="modal-body">
          <h3 class="modal-title text-center">Error</h3>
          <p class="text-center">An error occurred. Please try again later.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpRequest from '../mixins/http-request';

export default {
  name: 'ContactRelationshipManagerModal',
  props: {
    pageloadData: {
      type: Object
    },
    campaignContacts: {
      type: Object
    },
    campaignId: {
      type: String
    }
  },
  mixins: [httpRequest],
  data() {
    return {
      fullName: null,
      userEmail: null,
      emailSelected: 'userEmail',
      alternateEmail: null,
      contactNumber: null,
      message: null,
      image: null,
      base64: null,
      formattedFileSize: null,
      maxFileSize: 1000000,
      characterLimit: 1000,
      charactersRemaining: null,
      renderLoading: false,
      renderSuccess: false,
      renderAPIError: false,
      errors: {
        invalidEmail: false,
        invalidPhone: false,
        missingMessage: false,
        invalidFileType: false,
        invalidFileSize: false,
        characterLimit: false
      }
    }
  },
  watch: {
    pageloadData: {
      handler(newVal, oldVal) {
        if (!newVal) return;

        this.fullName = this.pageloadData.full_name;
        this.userEmail = this.pageloadData.email;
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    $('#contact-relationship-manager-modal').on('show.bs.modal', this.focus);
    $('#contact-relationship-manager-modal').on('hide.bs.modal', this.close);

    this.charactersRemaining = this.characterLimit;
  },
  methods: {
    resetErrors() {
      this.errors.invalidEmail = false;
      this.errors.invalidPhone = false;
      this.errors.missingMessage = false;
      this.errors.invalidFileType = false;
      this.errors.invalidFileSize = false;

    },
    onFileSelected(event) {
      this.image = event.target.files[0];

      // This resets the event and allows the same file to be removed and then re-added.
      event.target.value = null
    },
    onFileRemoved() {
      this.image = null;
      this.base64 = null;
      this.errors.invalidFileSize = false;
      this.errors.invalidFileType = false;
    },
    updateCharactersRemaining() {
      this.charactersRemaining = this.message ? this.characterLimit - this.message.length : this.characterLimit;

      this.errors.characterLimit = this.charactersRemaining < 0 ? true : false;
    },
    validateForm() {
      // Email Validity
      const emailRegex = /\S+@\S+\.\S+/;
      const emailValidity = emailRegex.test(this.alternateEmail);

      if (this.emailSelected != 'userEmail' && !emailValidity) this.errors.invalidEmail = true;

      // Phone Validity--not currently blocking submission
      const phoneRegex = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      const phoneValidity = phoneRegex.test(this.contactNumber);

      if (!phoneValidity && this.contactNumber != '' && this.contactNumber != null) this.errors.invalidPhone = true;

      // Message Validity
      if (this.message === null || this.message === '') this.errors.missingMessage = true;
      
      // Attachment Validity
      if (this.image) {
        if (this.image.type != 'image/jpeg' && this.image.type != 'image/png') this.errors.invalidFileType = true;
        if (this.image.size >= this.maxFileSize) {
          this.errors.invalidFileSize = true;
          this.formattedFileSize = parseFloat(this.image.size / 1000).toFixed(0);
        }
      }
    },
    createBase64() {
      const reader = new FileReader()

      reader.onloadend = () => {
        this.base64 = reader.result;
        this.sendMessage();
      }
      reader.readAsDataURL(this.image);
    },
    prepareMessage() {
      this.resetErrors();
      this.validateForm();

      if (this.errors.invalidEmail || this.errors.missingMessage || this.errors.invalidFileSize || this.errors.invalidFileType || this.errors.characterLimit) return
      
      this.image ? this.createBase64() : this.sendMessage();
    },
    async sendMessage() {
      const url = `${process.env.VUE_APP_PGM_API}/api/campaign/contactrelationshipmanager`;

      const body = JSON.stringify({
        name: this.fullName,
        contactEmail: this.emailSelected === 'userEmail' ? this.userEmail : this.alternateEmail,
        message: this.message,
        phoneNumber: this.contactNumber,
        imageName: this.image ? this.image.name : null,
        imageType: this.image ? this.image.type : null,
        base64: this.base64,
        campaignContacts: this.campaignContacts,
        campaignId: this.campaignId
      });

      this.renderLoading = true;
      const res = await this.httpPost(url, body);

      if (res.success) {
        this.renderLoading = false;
        this.renderSuccess = true;
      } else {
        this.renderLoading = false;
        this.renderAPIError = true;
      }
    },
    focus() {
      setTimeout(() => (document.getElementById("user-email")).focus(), 200);
    },
    close() {
      this.resetErrors();

      this.emailSelected = 'userEmail';
      this.alternateEmail = null;
      this.message = '';
      this.contactNumber = null;
      this.image = null;
      this.base64 = null;
      this.formattedFileSize = null;
      this.charactersRemaining = this.characterLimit;
      this.errors.invalidEmail = false;
      this.errors.invalidPhone = false;
      this.errors.missingMessage = false;
      this.errors.invalidFileType = false;
      this.errors.invalidFileSize = false;
      this.errors.characterLimit = false;

      setTimeout(() => this.closeRenders(), 200);
    },
    closeRenders() {
      this.renderLoading = false;
      this.renderSuccess = false;
      this.renderAPIError = false;
    }
  }
}
</script>

<style scoped>

.pointer {
  cursor: pointer;
}

#message-sent {
  width: 70%;
  height: 70%;
  margin: auto;
  display: block;
}
</style>
