<template>
  <div id="jt-programmatic" class="container mt-4 mb-4">
    <div class="row">
      <div class="col-12">
        <div id="jt-programmatic-card" class="card border-0 w-100">
          <Header :pageloadData="pageloadData" :headerType="0" />
          <!-- BEGIN: Content -->
          <div v-if="renderData" id="jt-programmatic-body" class="card-body">
            <div v-if="isMultipleDivisions" class="row filter-section mb-4">
              <div class="col-4">
                <SelectDivisions
                  @change-division="changeSelectedDivision"
                  :divisions="pageloadData.divisions_info"
                  :selectedDivIds="selectedDivIds"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <JobsTable :url="selectJobsUrl" :dataTablesType="2" />
              </div>
            </div>
          </div>
          <!-- END: Content -->
          <div v-else id="jt-programmatic-body" class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Header from '../../components/Header.vue';
import SelectDivisions from '../../components/SelectDivisions.vue';
import JobsTable from '../../components/JobsTable.vue';
import store from '../../store/index';
import httpRequest from '../../mixins/http-request';

export default {
  name: 'SelectJobs',
  components: {
    Header,
    SelectDivisions,
    JobsTable,
  },
  mixins: [httpRequest],
  data() {
    return {
      userState: store.state,
      router: this.$router,
      renderData: false,
      isMultipleDivisions: false,
      campaignId:null,
      pageloadData: null,
      selectedDivIds: null,
      selectJobsUrl: null,
    }
  },
  watch: {
    selectedDivIds: {
      handler(newVal, oldVal) {
        if (!newVal) return;

        this.loadData();
      },
      deep: true
    },
    userState: {
      async handler(newVal, oldVal) {
        if (!newVal) return;
        this.renderData = false;

        await this.pageload();

        if (this.pageloadData.redirect_scheduled) return this.router.replace({ path: '/sell-info' });

        this.selectedDivIds = this.pageloadData.divisions_info.map(val => val.id);
        this.isMultipleDivisions = this.selectedDivIds.length > 1;

        // redirect to error page if page load is null
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async loadData() {
      this.generateSelectJobsUrl();

      this.renderData = true;
    },
    async pageload() {
      const url = `${process.env.VUE_APP_PGM_API}/api/pageload/selectjobs?recruiter_id=${this.userState.navBarData.recruiterId}&company_id=${this.userState.navBarData.company.companyId}`;

      this.pageloadData = await this.httpGet(url);
    },
    generateSelectJobsUrl() {
      this.selectJobsUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/getunpostedjobs?campaign_id=${this.pageloadData.campaign_details[0].id}&divisions=${this.selectedDivIds}&type=0`;
    },
    changeSelectedDivision(selected) {
      this.renderData = false;
      this.selectedDivIds = selected;
    },
  }
}
</script>

<style scoped>

</style>