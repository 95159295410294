const ProductTiersEnum = Object.freeze({
  PROGRAMMATIC_PLUS: 'Programmatic+',
  PROGRAMMATIC_PLUS_POST_PAY: 'Programmatic+ Post Pay',
  PROGRAMMATIC_360: 'Programmatic 360',
  PROGRAMMATIC_360_POST_PAY: 'Programmatic 360 Post Pay',
  PROGRAMMATIC_CREDITS: 'Programmatic Credits'
});

module.exports = {
  isValid: (data) => Object.values(ProductTiersEnum).includes(data.toLowerCase()),
  getAllValues: () => Object.values(ProductTiersEnum),
  programmaticPlus: ProductTiersEnum.PROGRAMMATIC_PLUS,
  programmaticPlusPostPay: ProductTiersEnum.PROGRAMMATIC_PLUS_POST_PAY,
  programmatic360: ProductTiersEnum.PROGRAMMATIC_360,
  programmatic360PostPay: ProductTiersEnum.PROGRAMMATIC_360_POST_PAY,
  programmaticCredits: ProductTiersEnum.programmaticCredits
}