<template>
  <div :id="chartName" class="sparkline-analytic"></div>
</template>

<script>
export default {
  name: 'SparklineChart',
  props: {
    name: {
      type: String,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      chartName: `${this.name}-sparkline-graph`,
      chartData: [0, 0, 0, 0, 0, 0, 0]
    }
  },
  mounted() {
    for (let index = 0; index < this.seriesData.length; index++) {
        const element = [index, this.seriesData[index] ? this.seriesData[index] : 0]
        this.chartData[index] = element
    }

    Highcharts.chart(this.chartName, {
      chart: {
        type: 'column',
        backgroundColor: 'rgb(0,0,0,0)',
        marginTop: 0,
        marginRight: 0,
        marginBottom: 2,
        marginLeft: 0,
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        formatter: function (data) {
            const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            const day = days[this.x]

            return `${day} ${this.y}`;
        },
      },
      plotOptions: {
        series: {
          color: '#D5E7F8',
         	groupPadding: 0,
         	pointPadding: 0.1,
         	borderWidth: 0
        },
        column: {
          stacking: 'normal',
          minPointLength: 2
        },
      },
      exporting: {
        enabled: false,
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      series: [
        {
          data: this.chartData,
          showInLegend: false,
        },
      ],
    });
  },
};
</script>

<style scoped>
.sparkline-analytic {
  height: 35px;
  width: 60%;
  margin: 0 auto;
}

#clicks-sparkline-graph, #apps-sparkline-graph, #conversion-rate-sparkline-graph {
  margin-top: 16px;
  margin-bottom: 2px;
}
</style>