<template>
  <div class="form-group mb-0">
    <label :for="title" class="filter-label">Division</label>
    <select
      :id="title"
      class="form-control selectpicker"
      data-display="static"
      data-live-search-placeholder="Search Divisions"
      :data-live-search="showSearchDivisions"
      v-model="selected"
      @change="changeDivision"
      tabindex="-98"
    >
      <option :value="divisions.map(val => val.id)"><span class="select-all-name">All</span></option>
      <option
        v-for="division in divisions"
        :data-content="displayDivisionContent(division)"
        :key="division.id"
        :value="division.id"
      >
        {{ division.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectDivisions',
  emits: ['change-division'],
  props: {
    divisions: {
      type: Array,
      required: true
    },
    selectedDivIds: {
      type: [Array, Number],
      required: true
    }
  },
  data() {
    return {
      title: 'select-division',
      selected: this.selectedDivIds,
    }
  },
  computed: {
    showSearchDivisions() {
      return this.divisions.length > 5;
    },
  },
  mounted() {
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    $(`#${this.title}`).selectpicker({
      style: '',
      styleBase: 'form-control',
      size: 6,
      dropupAuto: false,
    });
  },
  methods: {
    changeDivision() {
      this.$emit('change-division', this.selected);
    },
    displayDivisionContent(division) {
      const name = document.createElement('span');

      name.setAttribute('class', 'select-division-name');
      name.textContent = `${division.name}`;

      return `${name.outerHTML}`;
    }
  }
};
</script>

<style>
.select-division-name, .select-all-name {
  font-size: 14px;
  color: #3F4254;
  line-height: 22px;
}
</style>
