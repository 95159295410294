<template>
	<div class="col-12">
		<div class="alert alert-warning d-flex align-items-center justify-content-between mb-0 pt-2 pb-2">
			<i class="fal fa-exclamation-triangle text-2"></i>
			<p class="mb-0 w-100 pl-5">You're running low on budget! <a id="click-here-text" href="#" data-target="#update-budget-modal" data-toggle="modal">Click here</a> to increase your budget and keep your job ads active, or contact your Relationship Manager.</p>
			<button type="button" class="btn btn-transparent text-warning close" data-dismiss="alert">
				<i class="fal fa-times text-2"></i>
			</button>
		</div>
	</div>
</template>

<script>
export default {
  name: 'BudgetAlert',
}
</script>

<style scoped>
#click-here-text {
	color: #855700;
	text-decoration: underline;
}
</style>