<template>
  <div class="modal fade" id="spend-limit-modal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Enter New Job Spend Target</h5>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div v-if="!renderLoading && !isSuccess" class="modal-body">
          <form @submit.prevent="onConfirmSpendLimit">
            <div class="form-group row">
              <small class="col-12"> 
                <p class="mb-4">Enter a target amount of spend that you are looking to achieve for this position in the current cycle, then click "Submit" button.</p>
                <p class="mb-2 text-dark font-weight-bold">Note:</p>
                <p class="mb-2">To remove a spend target entirely, please delete any existing value so the field is blank, then hit "Submit". </p>
                <p class="mb-2">Due to reconciliation processes, the total amount of spend accrued for this job in this cycle may be less than or more than your target spend amount.</p>
                <p class="mb-2">Spend target values entered here are valid for the current cycle only and must be resubmitted for any future cycles.</p>
              </small>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <input type="text" class="form-control" v-model="newSpendLimit" :placeholder="newSpendLimit" @blur="validateSpendLimit"/>
                <small v-if="errors.invalidNumber" class="text-danger">Please input a valid number</small>
                <small v-if="errors.invalidSpend" class="text-danger">
                  You cannot input an amount lower than the current spend. To pause this job, enter the current spend amount ({{this.spendLimitData.spend}}).
                </small>
                <small v-if="errors.invalidEmptySpend" class="text-danger">
                  You cannot input an empty Spend Target with No Target.
                </small>
              </div>
              <div class="col-12 mt-3 text-right">
                  <button type="button" class="btn btn-link" data-dismiss="modal">
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
        <div v-else-if="renderLoading && !isSuccess" class="modal-body">
          <div class="d-flex justify-content-center align-items-center">
            <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else-if="isSuccess" class="modal-body">
          <SuccessData :message="message" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpRequest from '../mixins/http-request';
import SuccessData from './SuccessData.vue';

export default {
  name: 'SpendLimitModal',
  components: {
    SuccessData,
  },
  data() {
    return {
      renderLoading: false,
      isSuccess: false,
      message: 'Succesfully updated job spend target',
      newSpendLimit: '',
      errors: {
        invalidNumber: false,
        invalidSpend: false,
        invalidEmptySpend: false,
      },
    }
  },
  props: {
    spendLimitData: {
      type: [Object, null],
      required: true
    }
  },
  mixins: [httpRequest],
  watch: {
     spendLimitData() {
        this.newSpendLimit = this.spendLimitData ? this.spendLimitData.spend_limit : '';
     }
  },
  mounted() {
    $('#spend-limit-modal').on('hide.bs.modal', this.close);
  },
  methods: {
    close() {
      this.$emit('close');
      this.resetErrors();
      this.renderLoading = false;
      this.isSuccess = false;
    },
    resetErrors() {
      this.errors.invalidNumber = false;
      this.errors.invalidSpend = false;
      this.errors.invalidEmptySpend = false;
    },
    async onConfirmSpendLimit() {
      const data = this.spendLimitData;
      if (!data) return;

      if (!this.newSpendLimit && !data.spend_limit) this.errors.invalidEmptySpend = true;

      if (this.errors.invalidNumber || this.errors.invalidSpend || this.errors.invalidEmptySpend) return;

      this.renderLoading = true;

      const url = `${process.env.VUE_APP_PGM_API}/api/campaign/updatejobspendlimit?division_id=${data.division_id}&campaign_id=${data.campaign_id}&cycle_id=${data.cycle_id}&job_id=${data.job_id}&ratio=${data.ratio}&spend=${data.spend}`;
      const payload = JSON.stringify({ new_spend_limit: this.newSpendLimit });

      await this.httpPost(url, payload);
      
      this.isSuccess = true;

      // send the updated Spend limit to the jobs table
      this.$emit('update:spendLimit', this.newSpendLimit, this.spendLimitData);
      return;
    },
    validateSpendLimit() {
      this.resetErrors();
 
      if(!this.newSpendLimit) return;

      const newLimit = parseFloat(this.newSpendLimit);
      if (isNaN(newLimit)) this.errors.invalidNumber = true;
      if (newLimit < parseFloat(this.spendLimitData.spend) && newLimit !== 0) this.errors.invalidSpend = true;
    }
  },
};
</script>

<style scoped>
</style>