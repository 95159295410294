<template>
  <a v-if="isClickableTile" @click="clickableTileHandler" @keydown.enter="clickableTileHandler" tabindex="0" class="container">
    <p class="card-value">
      <slot name="value" />
    </p>
    <p class="card-title">
      <slot name="title" />
    </p>
    <p class="card-value2">
      <slot name="value2" />
    </p>
  </a>

  <div v-else class="container">
    <p class="card-value">
      <slot name="value" />
    </p>
    <p class="card-title">
      <slot name="title" />
    </p>
    <p class="card-value2">
      <slot name="value2" />
    </p>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsCard',
  props: {
    isClickableTile: {
      type: Boolean,
      required: true
    },
    clickableTileReqs: {
      type: [Object, null],
      required: true
    }
  },
  methods: {
    clickableTileHandler(event) {

      // Apps tiles should not fire links if in iframe, budget modal still can

      const appsTileAll = document.querySelector('.apps-tile-all');
      const appsTileJob = document.querySelector('.apps-tile-job');
      const budgetTile = document.querySelector('.budget-tile');

      if (appsTileAll === event.target || appsTileAll === event.target.parentElement) {
        const url = `${process.env.VUE_APP_CANDIDATE_MANAGER_URL}/source/all_candidates`;
        appsTileAll.setAttribute("target", "_blank");
        appsTileAll.setAttribute("href", url);
      } else if (appsTileJob === event.target || appsTileJob === event.target.parentElement) {
        const url = `${process.env.VUE_APP_CANDIDATE_MANAGER_URL}/source/job_applicants?jobId=${this.clickableTileReqs.job_id}&divisionId=${this.clickableTileReqs.division_id}`;
        appsTileJob.setAttribute("target", "_blank");
        appsTileJob.setAttribute("href", url);
      } else if (budgetTile === event.target || budgetTile === event.target.parentElement) {
        budgetTile.setAttribute("href", "#");
        budgetTile.setAttribute("data-toggle", "modal");
        budgetTile.setAttribute("data-target", "#update-budget-modal");
      }
    }
  }
}
</script>

<style scoped>
.card-value {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #212529;
  margin-top: 32px;
  margin-bottom: 8px;
  display: inline-block;
  text-align: center;
}

.card-title {
  font-size: 16px;
  line-height: 24px;
  color: #495057;
  margin-bottom: 0;
}

.card-value2 {
  font-size: 12px;
  line-height: 18px;
  color: #6C757D;
  margin-bottom: 0;
}

.clickable-tile > .card-value {
  border-bottom: 1px #212529 dashed;
}
</style>
