<template>
  <div class="container">
    <div class="card">
      <div v-if="inventory.inventoryType === 'Credit'" class="card-body">
        <span class="pr-3"><span class="font-weight-semi-bold">Credits Available:</span> {{inventory.creditsAvailable}}/{{inventory.creditsTotal}}</span>
        <span class="text-dark-fill">│</span>
        <span class="pl-3"><span class="font-weight-semi-bold">Expires On:</span> {{inventory.expireDate}}</span>
      </div>
      <div v-if="inventory.inventoryType === 'Subscription'" class="card-body">
        <span class="pr-3"><span class="font-weight-semi-bold">Monthly Budget:</span> {{inventory.monthlyBudget}}</span>
        <span class="text-dark-fill">│</span>
        <span class="pl-3"><span class="font-weight-semi-bold">Current Cycle Budget:</span> {{inventory.currentCycleBudget}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InventoryInfo',
  props: {
    inventory: {
      type: Object
    },
  }
}
</script>

<style scoped>

</style>