<template>
	<div class="col-12">
		<div v-if="creditsAvailable === 1" class="alert alert-warning d-flex align-items-center justify-content-between mb-0 pt-2 pb-2">
			<i class="fal fa-exclamation-circle text-2"></i>
			<p class="mb-0 w-100 pl-3">
				Your credit inventory is low. 
				<a class="link-text-warning" @click="requestCredit">Click Here</a> 
				to contact your Relationship Manager, {{relationshipManager.displayName}}, and renew your inventory.</p>
			<button type="button" class="btn btn-transparent text-warning close" data-dismiss="alert">
				<i class="fal fa-times"></i>
			</button>
		</div>
    <div v-if="creditsAvailable === 0" class="alert alert-danger d-flex align-items-center justify-content-between mb-0 pt-2 pb-2">
			<i class="fal fa-times-circle text-2"></i>
			<p class="mb-0 w-100 pl-3">
				Your credit inventory is exhausted. 
				<a class="link-text-danger" @click="requestCredit">Click Here</a> 
				to contact your Relationship Manager, {{relationshipManager.displayName}}, and renew your inventory.
			</p>
			<button type="button" class="btn btn-transparent text-danger close" data-dismiss="alert">
				<i class="fal fa-times"></i>
			</button>
		</div>
		<CreditRequestConfirmModal :success="success"/>
	</div>
</template>

<script>
import CreditRequestConfirmModal from './CreditRequestConfirmModal.vue';
import httpRequest from '../mixins/http-request';

export default {
  name: 'CreditAlert',
	components: {
		CreditRequestConfirmModal
	}, 
	props: {
		creditsAvailable: {
			type: Number
		},
		relationshipManager: {
			type: Object
		},
    pageload: {
      type: Object
    },
	},
	mixins: [httpRequest],
	data() {
		return {
			success: false
		}
	},
	methods: {
		async requestCredit() {
			const body = JSON.stringify({
				userName: this.pageload.full_name,
				userEmail: this.pageload.email,
				campaignId: this.pageload.campaign_id,
				relationshipManager: this.relationshipManager
			})

			const url = `${process.env.VUE_APP_PGM_API}/api/campaign/requestcredit`
			const res = await this.httpPost(url, body);

			if (res.success) this.success = true;

			$('#credit-request-confirm-modal').modal('show');
		}
	}
}
</script>

<style scoped>
.link-text-warning {
	color: #855700;
  text-decoration: underline;
	cursor: pointer;
}

.link-text-warning:hover {
	color: #853900;
}

.link-text-danger {
	color: #802932;
  text-decoration: underline;
	cursor: pointer;
}

.link-text-danger:hover {
	color: #67050F;
}
</style>