<template>
  <div id="jt-programmatic" class="container mt-4 mb-4">
    <div class="row">
      <div class="col-12">
        <div id="jt-programmatic-card" class="card border-0 w-100">
          <Header :pageloadData="pageloadData" :headerType="0" />
          <!-- BEGIN: Content -->
          <div v-if="renderData" id="jt-programmatic-body" class="card-body">
            <div class="row filter-section mb-4">
              <div v-if="isMultipleDivisions" class="col-6">
                <SelectDivisions
                  :divisions="pageloadData.divisions_info"
                  :selectedDivIds="selectedDivIds"
                  @change-division="changeSelectedDivision"
                />
              </div>
              <div class="col-6">
                <DateRangePicker
                  :singleDate="changeDateRange"
                  :dateRangeType="1"
                  @select-date-range="selectDateRange"
                />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <AnalyticsCards
                  :analyticsData="analyticsData" 
                  :clickableTileReqs="clickableTileReqs"
                />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <DailyTrafficChart
                  :url="singlesChartUrl"
                  :totalApps="totalApps"
                  :chartType="1"
                  @chart-length="showNoActivePostings"
                  @update-sparkline="displaySparklineGraph"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <JobsTable
                  :url="singleJobsUrl"
                  :jobDateRange="changeDateRange"
                  :isCompanyEAEnabled="isCompanyEAEnabled"
                  :dataTablesType="1"
                />
              </div>
            </div>
            <NoActivePostingsModal :startDate="startDate" :endDate="endDate" />
          </div>
          <!-- END: Content -->
          <div v-else id="jt-programmatic-body" class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import SelectDivisions from '../../components/SelectDivisions.vue';
import DateRangePicker from '../../components/DateRangePicker.vue';
import AnalyticsCards from '../../components/AnalyticsCards.vue';
import DailyTrafficChart from '../../components/DailyTrafficChart.vue';
import JobsTable from '../../components/JobsTable.vue';
import NoActivePostingsModal from '../../components/NoActivePostingsModal.vue';
import store from '../../store/index';
import httpRequest from '../../mixins/http-request';

export default {
  name: 'Singles',
  components: {
    Header,
    SelectDivisions,
    DateRangePicker,
    AnalyticsCards,
    DailyTrafficChart,
    JobsTable,
    NoActivePostingsModal,
  },
  mixins: [httpRequest],
  data() {
    return {
      userState: store.state,
      router: this.$router,
      renderData: false,
      isMultipleDivisions: false,
      startDate: moment().subtract(13, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      pageloadData: null,
      selectedDivIds: null,
      singlesChartUrl: null,
      singleJobsUrl: null,
      totalApps: 0,
      clickableTileReqs: {},
      analyticsData: [
        {
          name: 'Clicks',
          value: '—',
          className: 'col analytics-card card-no-right-border card-left-radius',
          isClickableTile: false,
        },
        {
          name: 'Apps',
          value: '—',
          className: 'col analytics-card card-no-right-border apps-tile-all',
          isClickableTile: false,
        },
        {
          name: 'Conversion Rate',
          value: '—',
          className: 'col analytics-card card-all-border card-right-radius',
          isClickableTile: false,
        }
      ],
    };
  },
  computed: {
    changeDateRange() {
      const { startDate, endDate } = this;

      return { startDate, endDate };
    },
  },
  watch: {
    changeDateRange: {
      async handler(newVal, oldVal) {
        if (!newVal.startDate || !newVal.endDate) return;

        await this.loadData();
      }
    },
    selectedDivIds: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        await this.loadData();
      }
    },
    userState: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        this.resetSinglesData();
        await this.pageload();

        if (this.pageloadData.redirect_dashboard) return this.router.replace({ path: '/dashboard' });

        this.selectedDivIds = this.pageloadData.divisions_info.map(val => val.id);
        this.isMultipleDivisions = !this.selectedDivIds ? false : this.selectedDivIds.length > 1;
        this.isCompanyEAEnabled = this.pageloadData.company_ea_enabled;

        // redirect to error page if page load is null
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async loadData() {
      if (this.selectedDivIds === null) return;

      await this.getAnalyticsData();

      this.generateSingleJobsUrl();
      this.generateSinglesChartUrl();
      this.makeTileClickable();
      this.renderData = true;
    },
    async pageload() {
      const url = `${process.env.VUE_APP_PGM_API}/api/pageload/single?recruiter_id=${this.userState.navBarData.recruiterId}`;

      this.pageloadData = await this.httpGet(url);
    },
    async getAnalyticsData() {
      const url = `${process.env.VUE_APP_PGM_API}/api/single/divisionoverview?div_ids=${this.selectedDivIds}&start=${this.startDate}&end=${this.endDate}`;

      const result = await this.httpGet(url);

      this.analyticsData[0].value = numeral(result.clicks).format('0,0');
      this.analyticsData[1].value = numeral(result.apps).format('0,0');

      if (result.clicks > 0) {
        this.analyticsData[2].value = numeral(result.apps / result.clicks).format('0.00%');
      }

      if (result.apps > 0) {
        this.totalApps = result.apps;
      }
    },
    generateSinglesChartUrl() {
      this.singlesChartUrl = `${process.env.VUE_APP_PGM_API}/api/single/singlesdivisionoverview?div_ids=${this.selectedDivIds}&start=${this.startDate}&end=${this.endDate}`;
    },
    generateSingleJobsUrl() {
      this.singleJobsUrl = `${process.env.VUE_APP_PGM_API}/api/single/getsinglesindivision?div_ids=${this.selectedDivIds}&start=${this.startDate}&end=${this.endDate}`;
    },
    selectDateRange({newStartDate, newEndDate}) {
      if (this.startDate === newStartDate && this.endDate === newEndDate) return;

      this.resetSinglesData();
      this.selectedDivIds = this.pageloadData.divisions_info.map(val => val.id);
      this.startDate = newStartDate;
      this.endDate = newEndDate;
    },
    changeSelectedDivision(selected) {
      this.resetSinglesData();
      this.selectedDivIds = selected;
    },
    showNoActivePostings(length) {
      if (length === 0) {
        $('#no-active-postings-modal').modal('show');
      }
    },
    displaySparklineGraph(data) {
      this.analyticsData[0].graphData = data.clicks;
      this.analyticsData[1].graphData = data.apps;
      this.analyticsData[2].graphData = data.conversion.map(data => Number(data.toFixed(2)));
    },
    makeTileClickable() { 
      // If not iframe
      if (this.isCompanyEAEnabled && this.totalApps > 0) {
        this.analyticsData[1].className = this.analyticsData[1].className + ' clickable-tile';
        this.analyticsData[1].isClickableTile = true;
      }
    },
    resetSinglesData() {
      this.renderData = false;
      this.startDate = moment().subtract(13, 'days').format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');
      this.selectedDivIds = null;
      this.singlesChartUrl = null;
      this.singlesJobsUrl = null;
      this.totalApps = 0;
      this.analyticsData = [
        {
          name: 'Clicks',
          value: '—',
          className: 'col analytics-card card-no-right-border card-left-radius',
          isClickableTile: false,
        },
        {
          name: 'Apps',
          value: '—',
          className: 'col analytics-card card-no-right-border apps-tile-all',
          isClickableTile: false,
        },
        {
          name: 'Conversion Rate',
          value: '—',
          className: 'col analytics-card card-all-border card-right-radius',
          isClickableTile: false,
        }
      ]
    }
  }
};
</script>

<style scoped>
</style>
