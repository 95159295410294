<template>
  <div class="modal fade" id="job-description-modal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="col-10 pl-0 pr-0">
            <h5 v-if="jobDescriptionData" class="modal-title">{{ jobDescriptionData.job_name }} <small>({{ jobDescriptionData.job_id }})</small></h5>
          </div>
          <div class="col-2 pl-0 pr-0 text-right">
            <a v-if="showViewJobButton" id="view-job" :href="redirectViewJob" class="btn btn-outline-primary btn-sm" target="_blank">View Job</a>
            <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close">
              <span id="close-font" aria-hidden="true">
                <i class="fal fa-times text-15 text-dark-fill"></i>
              </span>
            </button>
          </div>
        </div>
        <div v-if="renderData && isDataFound" class="modal-body">
          <AnalyticsCards 
            :analyticsData="analyticsData" 
            :clickableTileReqs="jobDescriptionData"
          />
          <JobTrafficChart
            :chartData="jobData"
            :totalApps="totalApps"
            :campaignRatio="campaignRatio"
            :chartType="dataTablesType"
          />
        </div>
        <div v-if="renderData && !isDataFound" class="modal-body">
          <JobNoData />
        </div>
        <div v-else-if="!renderData" class="modal-body">
          <div class="d-flex justify-content-center align-items-center">
            <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsCards from './AnalyticsCards.vue';
import JobTrafficChart from './JobTrafficChart.vue';
import JobNoData from './JobNoData.vue';
import httpRequest from '../mixins/http-request';

export default {
  name: 'JobDescriptionModal',
  components: {
    AnalyticsCards,
    JobTrafficChart,
    JobNoData
  },
  props: {
    jobDescriptionData: {
      type: [Object, null],
      required: true
    },
    dataTablesType: {
      type: Number,
      required: true
    },
    campaignRatio: {
      type: Number
    },
    jobDateRange: {
      type: Object,
    },
    isCompanyEAEnabled: {
      type: Boolean
    }
  },
  mixins: [httpRequest],
  data() {
    return {
      renderData: false,
      isDataFound: false,
      totalApps: 0,
      jobData: [],
      analyticsData: []
    }
  },
  computed: {
    redirectViewJob() {
      return !this.jobDescriptionData ? '#' : `${process.env.VUE_APP_JOB_MANAGER_URL}/details/${this.jobDescriptionData.job_id}`;
    },
    showViewJobButton(){
      const url = (window.location != window.parent.location) ? document.referrer : document.location.href;
      return url.includes('pgm.jobtarget.com');
    }
  },
  watch: {
    async jobDescriptionData() {
      if (!this.jobDescriptionData) return;

      if (this.dataTablesType === 0) {
        await this.getCampaignJobData();
        this.getCampaignJobAnalyticsData();
        this.renderData = true;
      } else if (this.dataTablesType === 1) {
        await this.getSinglesJobData();
        this.getSinglesJobAnalyticsData();
        this.renderData = true;
      }
    },
    jobData() {
      this.isDataFound = this.jobData && this.jobData.length > 0; 
    }
  },
  mounted() {
    $('#job-description-modal').on('hide.bs.modal', this.close);
  },
  methods: {
    close() {
      this.jobData = [];
      this.analyticsData = [];
      this.renderData = false;
      this.$emit('close');
    },
    async getCampaignJobData() {
      const url = `${process.env.VUE_APP_PGM_API}/api/campaign/cyclejoboverview?cycle_id=${this.jobDescriptionData.cycle_id}&job_id=${this.jobDescriptionData.job_id}`;

      this.jobData = await this.httpGet(url);
    },
    getCampaignJobAnalyticsData() {
      const jobAnalyticsData = [
        {
          name: 'Clicks',
          value: '—',
          className: 'col analytics-card card-no-right-border card-left-radius',
          isClickableTile: false,
        },
        {
          name: 'Apps',
          value: '—',
          className: 'col analytics-card card-no-right-border apps-tile-job',
          isClickableTile: false,
        },
        {
          name: 'Spend',
          value: '—',
          className: 'col analytics-card card-all-border card-right-radius',
          isClickableTile: false,
        }
      ];

      const days = this.jobData.length;
      const clicks = this.jobData.reduce((n, {clicks}) => n + clicks, 0);
      const apps = this.jobData.reduce((n, {apps}) => n + apps, 0);
      const spend = this.jobData.reduce((n, {spend}) => n + (spend / this.jobDescriptionData.ratio), 0);

      const displaySpendLimit = this.jobDescriptionData.spend_limit ? ` | $${this.jobDescriptionData.spend_limit} Limit` : '';
      this.totalApps = apps;

      if (this.isCompanyEAEnabled && this.totalApps > 0) {
        jobAnalyticsData[1].className = jobAnalyticsData[1].className + ' clickable-tile';
        jobAnalyticsData[1].isClickableTile = true;
      }

      jobAnalyticsData[0].value = numeral(clicks).format('0,0');
      jobAnalyticsData[1].value = numeral(apps).format('0,0');
      jobAnalyticsData[2].value = numeral(spend).format('$0.00');
      jobAnalyticsData[0].value2 = `${numeral(clicks / days).format('0,0')} / day`;
      jobAnalyticsData[1].value2 = `${numeral(apps / days).format('0,0')} / day`;
      jobAnalyticsData[2].value2 = `${numeral(spend / days).format('$0,0.00')} / day${displaySpendLimit}`;

      this.analyticsData = jobAnalyticsData;
    },
    async getSinglesJobData() {
      const url = `${process.env.VUE_APP_PGM_API}/api/single/singledivisionoverview?job_id=${this.jobDescriptionData.job_id}&start=${this.jobDateRange.startDate}&end=${this.jobDateRange.endDate}`;

      this.jobData = await this.httpGet(url);
    },
    getSinglesJobAnalyticsData() {
      const jobAnalyticsData = [
        {
          name: 'Clicks',
          value: '—',
          className: 'col analytics-card card-no-right-border card-left-radius',
          isClickableTile: false,
        },
        {
          name: 'Apps',
          value: '—',
          className: 'col analytics-card card-no-right-border apps-tile-job',
          isClickableTile: false,
        },
        {
          name: 'Conversion Rate',
          value: '—',
          className: 'col analytics-card card-all-border card-right-radius',
          isClickableTile: false,
        }
      ];

      jobAnalyticsData[2].value = 'N/A';
      const days = this.jobData.length;
      const clicks = this.jobData.reduce((n, {clicks}) => n + clicks, 0);
      const apps = this.jobData.reduce((n, {apps}) => n + apps, 0);
      this.totalApps = apps;

      if (this.isCompanyEAEnabled && this.totalApps > 0) {
        jobAnalyticsData[1].className = jobAnalyticsData[1].className + ' clickable-tile';
        jobAnalyticsData[1].isClickableTile = true;
      }

      jobAnalyticsData[0].value = numeral(clicks).format('0,0');
      jobAnalyticsData[1].value = numeral(apps).format('0,0');
      jobAnalyticsData[0].value2 = `${numeral(clicks / days).format('0,0')} / day`;
      jobAnalyticsData[1].value2 = `${numeral(apps / days).format('0,0')} /  day`;

      if (clicks > 0) {
        const conversionRate = apps / clicks;
        jobAnalyticsData[2].value = numeral(conversionRate).format('0,0.00%');
      }

      this.analyticsData = jobAnalyticsData;
    }
  }

}
</script>

<style scoped>
</style>