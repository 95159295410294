<template>
  <div class="d-flex justify-content-center align-items-center">
    <object :data="jobNoDataImg" height="400"></object>
  </div>
  <h2 class="text-center">No Data Report</h2>
  <p id="historical-data-text" class="text-center">This job has no historical data at this point. Please check back later</p>
</template>

<script>
export default {
  name: 'JobNoData',
  data() {
    return {
      jobNoDataImg: require('../assets/img/no-data-report.svg'),
    }
  }
}
</script>

<style scoped>
#historical-data-text {
  font-size: 14px;
  color :#495057;
}
</style>
