<template>
  <div :id="title"></div>
</template>

<script>
export default {
  name: 'JobTrafficChart',
  props: {
    chartData: {
      type: Array,
      required: true
    },
    totalApps: {
      type: Number,
      required: true
    },
    campaignRatio: {
      type: Number
    },
    chartType: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      title: 'job-traffic-chart',
      startDate: [],
      clicks: [],
      apps: [],
      spend: [],
      cpc: [],
      maxCpc: Number.MAX_VALUE,
      minCpc: Number.MIN_VALUE,
      days: 0,
    }
  },
  watch: {
    chartData() {
      this.resetChartData();
      this.addChartData();
    }
  },
  mounted() { // initial data for singles page
    this.resetChartData();
    this.addChartData();
  },
  beforeUnmount() {
    const chart = $(`#${this.title}`).highcharts();

    if (chart) chart.destroy();
  },
  methods: {
    displayYAxis() {
      let yAxis = [
          { // Primary yAxis
            title: {
              text: 'Traffic'
            }
          }];
      if (this.chartType === 0) {
        yAxis.push(
          { // 2nd yAxis
            title: {
              text: 'Apps'
            }
          },
          { // 3rd yAxis
            title: {
              text: 'Spend'
            },
            opposite: true
        });
      } else {
        yAxis.push(
          { // 2nd yAxis
            title: {
              text: 'Apps'
            },
            opposite: true
          }
        );
      }

      return yAxis;
    },
    displaySeries() {
      let series = [
          {
            name: 'Traffic',
            yAxis: 0,
            data: this.clicks
          }, {
            name: 'Apps',
            yAxis: 1,
            data: this.apps,
            visible: this.totalApps > 0 ? true : false
          },
        ]
      if (this.chartType === 0) {
        series.push({
          name: 'Spend',
          yAxis: 2,
          data: this.spend,
          tooltip: {
            valuePrefix: '$ '
          }
        });
      }

      return series;
    },
    resetChartData() {
      this.startDate = [];
      this.clicks = [];
      this.apps = [];
      this.spend = [];
      this.cpc = [];
      this.maxCpc = Number.MAX_VALUE;
      this.minCpc = Number.MIN_VALUE;
      this.days = 0;
    },
    isSpendValue(spend) {
      if (this.chartType === 0) {
        return parseFloat(spend / this.campaignRatio);
      } else {
        return parseFloat(spend * 2);
      }
    },
    addChartData() {
      if (this.chartData.length < 1) return;

      this.startDate = this.chartData[0].date.split('-');

      this.chartData.forEach(data => {
        const jobDate = data.date.split('-');
        const spend = this.isSpendValue(data.spend);

        this.clicks.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), data.clicks]);
        this.apps.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), data.apps]);
        const jobCpc = parseFloat((data.spend / data.clicks).toFixed(2));
        if (jobCpc > this.maxCpc) this.maxCpc = jobCpc;
        if (jobCpc < this.minCpc) this.minCpc = jobCpc;
        this.cpc.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), jobCpc]);
        this.spend.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), parseFloat(spend.toFixed(2))]);
      });

      this.displayHighChart();
    },
    displayHighChart() {
      const self = this;
      Highcharts.chart(self.title, {
        chart: {
          type: 'spline',
          zoomType: 'x'
        },
        title: {
          text: 'Daily Traffic',
          style: {
            color: '#212529',
            fontSize: '16px',
            fontWeight: '400'
          }
        },
        subtitle: {
          // document.ontouchstart === undefined ? subTitle[0] : subTitle[1]
          text: 'Click and drag in the plot area to zoom in',
          style: {
            color: '#212529',
            fontSize: '12px',
            fontWeight: '400'
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Date'
          },
          labels: {
            formatter() {
              return Highcharts.dateFormat('%b %e', this.value);
            }
          }
        },
        yAxis: self.displayYAxis(),
        tooltip: {
          shared: true
        },
        plotOptions: {
          spline: {
            lineWidth: 4,
            states: {
              hover: {
                lineWidth: 5
              }
            },
            marker: {
              enabled: false
            }
          }
        },
        series: self.displaySeries(),
        navigation: {
          menuItemStyle: {
            fontSize: '10px'
          }
        },
        exporting: {
          csv: {
            dateFormat: '%Y-%m-%d'
          },
          buttons: {
            contextButton: {
              menuItems: [
                'viewFullscreen',
                'printChart',
                'separator',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
                'downloadCSV',
                'downloadXLS',
                'viewData'
              ]
            }
          }
        }
      });
    }
  }
}

</script>

<style scoped>
  #siteoverviewchart {
    height: 300px;
  }
</style>