import store from '../store/index';

export default {
  methods: {
    async httpGet(url) {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: new Headers({
          Authorization: `Bearer ${store.methods.getJtToken()}`,
          'Content-Type': 'application/json',
        }),
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });

      // if token is not valid anymore, refresh the page to respect shared navbar authentication
      if (response.status === 401) window.location.reload();

      return response.json();
    },
    async httpPost(url, payload) {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: new Headers({
          Authorization: `Bearer ${store.methods.getJtToken()}`,
          'Content-Type': 'application/json',
        }),
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: payload
      });

      // if token is not valid anymore, refresh the page to respect shared navbar authentication
      if (response.status === 401) window.location.reload();

      return response.json();
    }
  }
}