<template>
  <div class="modal fade" id="credit-request-confirm-modal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Credit Inventory</h5>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div v-if="!success" class="modal-body">
          <div class="d-flex justify-content-center align-items-center">
            <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else class="modal-body">
            <img src="../assets/svg/message-sent.svg" alt="Message Sent"/>
            <h3 class="modal-title text-center">Request Sent!</h3>
            <p class="text-center">Your Relationship Manager will reach out to you shortly.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditRequestConfirmModal',
  props: {
    success: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    $('#credit-request-confirm-modal').on('hide.bs.modal', this.close);
  },
}
</script>

<style scoped>

</style>