<template>
  <div class="d-flex justify-content-center align-items-center">
    <object :data="imagePath"></object>
  </div>
  <p class="text-center">{{ message }}</p>
</template>

<script>
export default {
  name: 'SuccessData',
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imagePath: require('../assets/svg/message-sent.svg'),
    }
  }
}
</script>

<style scoped>
#historical-data-text {
  font-size: 14px;
  color :#495057;
}
</style>