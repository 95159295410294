<template>
  <jt-navbar :redirect="url" divisionflag="1"></jt-navbar>
  <router-view v-if="hasInitialized"/>
  <jt-footer></jt-footer>
</template>

<script>
import store from './store/index';
import cookie from 'js-cookie';

export default {
  name: 'App',
  data() {
    return {
      userState: store.state,
      router: this.$router,
      hasInitialized: false,
      url: process.env.VUE_APP_PGM_UI
    }
  },
  watch: {
    userState: {
      async handler(newVal, oldVal) {
        if (!newVal) return;
        if (!newVal.navBarData) return;

        if (!this.hasPermission()) return this.router.replace({ path: '/sell-info' });

        this.hasInitialized = true;
      },
      deep: true
    }
  },
  beforeCreate() {
    const jtName = 'jtToken';
    const urlParams = new URLSearchParams(window.location.search);
    const cookieJtToken = cookie.get(jtName);
    let navPoll = null;
    let currentDivisionId = 0;

    if (cookieJtToken) return navBarEventHandler();
    if (!urlParams.has(jtName)) return;

    const jtToken = urlParams.get(jtName);

    if (window.location.hostname.includes('.jobtarget.com')) {
      cookie.set(jtName, jtToken, {
        expires: 1,
        domain: '.jobtarget.com'
      });
    } else {
      cookie.set(jtName, jtToken, { expires: 1 });
    }

    return navBarEventHandler();

    function navBarEventHandler() {
      window.addEventListener('userinfo:jt:navbar', handleNavData);
      window.addEventListener('divisionchange:jt:navbar', handleNavData);

      navPoll = setInterval(pingNav, 250);
    }

    function pingNav() {
      window.dispatchEvent(new CustomEvent('requestuserinfo:jt:navbar'));
    }

    function handleNavData(event) {
      if (event.detail.currentDivision.id === 0) {
        return;
      } else {
        clearInterval(navPoll);
      }

      if (currentDivisionId !== event.detail.currentDivision.id) {
        currentDivisionId = event.detail.currentDivision.id;
        store.methods.setNavBarData(store.state, event.detail);
      }
    }
  },
  methods: {
    hasPermission() {
      if (!this.userState.navBarData.appPermissions) return;

      return this.userState.navBarData.appPermissions.some(data => data.app_id === 2);
    }
  }
}
</script>

<style>
.filter-section {
  background-color: #F8F9FB;
  margin-left: 0;
  margin-right: 0;
  padding-top: 24px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 24px;
}

.status-label { 
  font-size: 12px;
  font-weight: 500;
  padding: 1px 8px;
  line-height: 18px;
  border-radius: 4px;
}

.active-status {
  background: #C9F7F5;
  color: #11807A;
}

.inactive-status {
  background: #E9ECEF;
  color: #6C757D;
}

.in-progress-status {
  background: #FFF4DE;
  color: #855700;
}

.paused-status {
  background: #FFF4DE;
  color: #855700;
}

.limit-status {
  background: #FFE2E5;
  color: #802932;
}

.filter-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #343A40;
}

.dropdown-item .text {
  width: 100%;
}
</style>
