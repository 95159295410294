const JobActionEnum = Object.freeze({
  RESUME: 'resume',
  EXPIRE: 'expire'
});

module.exports = {
  isValid: (action) => Object.values(JobActionEnum).includes(action.toLowerCase()),
  getAllValues: () => Object.values(JobActionEnum),
  resume: JobActionEnum.RESUME,
  expire: JobActionEnum.EXPIRE
}