<template>
  <div class="modal fade" id="update-budget-modal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Update Budget</h5>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div v-if="!renderConfirm">
          <div v-if="!renderLoading" class="modal-body">
            <form autocomplete="off">
              <div class="form-row">
                <div class="col">
                  <label for="new-budget">New Monthly Budget<span class="text-danger">*</span></label>
                  <div class="input-group">  
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input type="numeric" class="form-control" id="new-budget" placeholder="Enter Amount" v-model="newMonthlyBudget">
                  </div>
                </div>
                <div class="col">
                  <label for="new-job-volume">New Job Volume</label>
                  <input type="numeric" class="form-control" id="new-job-volume" placeholder="Enter Job Volume" v-model="newJobVolume">
                </div>
              </div>
              <div class="form-group">
                <small v-if="errors.missingBudget" class="text-danger">
                  Please enter a required field.
                </small>
                <small v-else-if="errors.invalidBudgetNaN" class="text-danger">
                  Please enter a valid number without punctuation.
                </small>
                <small v-else-if="errors.invalidBudgetLow" class="text-danger">
                  Minimum budget increase must be $100 more than current budget, to reduce budget please contact your Relationship Manager.
                </small>
                <small v-else-if="errors.invalidBudgetHigh" class="text-danger">
                  Maximum budget increase is {{formattedMaxBudget}} (10x current budget), to increase budget to a larger value or reduce budget please contact your Relationship Manager.
                </small>
              </div>
              <h5>Increase Monthly Budget For:<span class="text-danger">*</span></h5>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" v-model="currentCycleSelected">
                  <span></span>
                  Current Cycle
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" v-model="futureCyclesSelected">
                  <span></span>
                  Future Cycles
                </label>
              </div>
              <small v-if="errors.invalidCycles" class="text-danger">
                Please check at least one of the above.
              </small>
              <p class="pt-3">By submitting this form you agree to pay JobTarget for the budget amount entered which incorporates the terms and conditions previously agreed to between Client and JobTarget.</p>
            </form>
          </div>
          <div v-if="!renderLoading" class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary" @click="prepareUpdateBudget">Update Budget</button>
          </div>
          <div v-else>
            <div class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="!renderLoading" class="modal-body">
            <p class="pt-3">
              Please confirm you would like to increase your budget to {{formattedNewMonthlyBudget}} for 
              <span v-if="currentCycleSelected">the current cycle </span>
              <span v-if="currentCycleSelected && futureCyclesSelected">and </span>
              <span v-if="futureCyclesSelected">future cycles </span>before proceeding.
            </p>
          </div>
          <div v-if="!renderLoading" class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary" @click="finalizeUpdateBudget">Confirm</button>
          </div>
          <div v-else>
            <div class="d-flex justify-content-center align-items-center modal-body">
              <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpRequest from '../mixins/http-request';

export default {
  name: 'UpdateBudgetModal',
  props: {
    pageloadData: {
      type: [Object, null],
      required: true
    },
    currentCycle: {
      type: [Object, null],
      required: true
    },
    campaignContacts: {
      type: [Object, null],
      required: true
    },
    campaignId: {
      type: [String, null],
      required: true
    }
  },
  mixins: [httpRequest],
  emits: ["update-budget-success"],
  data() {
    return {
      renderLoading: false,
      renderConfirm: false,
      fullName: null,
      email: null,
      currentCycleId: null,
      currentCycleRatio: null,
      previousBudget: null,
      newMonthlyBudget: null,
      formattedNewMonthlyBudget: null,
      formattedMaxBudget: null,
      newJobVolume: null,
      currentCycleSelected: true,
      futureCyclesSelected: true,
      errors: {
        invalidBudgetLow: false,
        invalidBudgetHigh: false,
        invalidBudgetNaN: false,
        missingBudget: false,
        invalidCycles: false,
      },
    };
  },
  watch: {
    pageloadData: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        if (newVal.page !== 0 || newVal.cycles.length === 0) return;
        this.fullName = this.pageloadData.full_name;
        this.email = this.pageloadData.email;
      },
      immediate: true,
      deep: false,
    },
    currentCycle: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        
        this.currentCycleId = this.currentCycle.campaign_cycle_id;
        this.previousBudget = this.currentCycle.customer_budget;
        this.formattedMaxBudget = numeral(this.previousBudget * 10).format('$0,0.00');
        this.currentCycleRatio = this.currentCycle.ratio;
      },
      immediate: true,
      deep: false,
    }
  },
  mounted() {
    $('#update-budget-modal').on('show.bs.modal', this.focus);
    $('#update-budget-modal').on('hide.bs.modal', this.close);
  },
  methods: {
    resetErrors() {
      this.errors.invalidBudgetLow = false;
      this.errors.invalidBudgetHigh = false;
      this.errors.invalidBudgetNaN = false;
      this.errors.missingBudget = false;
      this.errors.invalidCycles = false;
    },
    async prepareUpdateBudget() {
      this.validateMonthlyCycle();
      this.validateMonthlyBudget();

      if (this.campaignId === null || this.fullName === null || this.email === null || this.previousBudget === null) return;
      if (!this.newMonthlyBudget) this.errors.missingBudget = true;
      if (this.errors.invalidBudgetNaN || this.errors.invalidBudgetLow || this.errors.invalidBudgetHigh || this.errors.invalidCycles || this.errors.missingBudget) return;
      
      this.formattedNewMonthlyBudget = numeral(this.newMonthlyBudget).format('$0,0.00');
      this.renderConfirm = true;
    },
    async finalizeUpdateBudget() {
      this.renderLoading = true;

      const url = `${process.env.VUE_APP_PGM_API}/api/campaign/updatebudget`;

      const body = JSON.stringify({
        campaignId: this.campaignId,
        cycleId: this.currentCycleId,
        ratio: this.currentCycleRatio,
        userName: this.fullName.trim(),
        userEmail: this.email,
        previousBudget: this.previousBudget,
        newBudget: this.newMonthlyBudget,
        newJobVolume: this.newJobVolume,
        updateCurrentCycle: this.currentCycleSelected,
        updateFutureCycles: this.futureCyclesSelected,
        updateJobVolume: this.newJobVolume !== null,
        campaignContacts: this.campaignContacts
      })

      const res = await this.httpPost(url, body);

      this.renderLoading = false;

      const updatedBudgetData = {
        newBudget: this.newMonthlyBudget,
        currentCycleChanged: this.currentCycleSelected,
        futureCyclesChanged: this.futureCyclesSelected,
        previousCycleStatus: this.currentCycle.status_id,
        resumeCycle: res.resume_cycle,
        success: res.success
      }

      this.$parent.$emit('update-budget-success', updatedBudgetData);

      $('#update-budget-modal').modal('hide').hide();
    },
    focus() {
      setTimeout(() => (document.getElementById("new-budget")).focus(), 200);
    },
    validateMonthlyCycle() {
      this.errors.invalidCycles = false;
      if (!this.futureCyclesSelected && !this.currentCycleSelected) this.errors.invalidCycles = true;
    },
    validateMonthlyBudget() {
      this.errors.invalidBudgetLow = false;
      this.errors.invalidBudgetHigh = false;
      this.errors.invalidBudgetNaN = false;
      this.errors.missingBudget = false;

      const newBudget = parseFloat(this.newMonthlyBudget);

      if (isNaN(this.newMonthlyBudget) || isNaN(newBudget)) this.errors.invalidBudgetNaN = true;
      if ((this.previousBudget + 100) > newBudget) this.errors.invalidBudgetLow = true;
      if ((this.previousBudget * 10) < newBudget) this.errors.invalidBudgetHigh = true;
    },
    close() {
      this.resetErrors();

      this.renderLoading = false;
      this.renderConfirm = false;
      this.formattedNewMonthlyBudget = null;
      this.newMonthlyBudget = null;
      this.newJobVolume = null;
      this.currentCycleSelected = true;
      this.futureCyclesSelected = true;
      this.currentCycleDisabled = false;
      this.url = null;
    }
  },
}
</script>

<style scoped>
</style>
