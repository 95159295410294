<template>
  <div class="modal fade" id="action-job-modal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ jobTitle }} Job</h5>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div v-if="jobAction === jobActionEnum.resume && !renderLoading && !isSuccess" class="modal-body text-sm-75">
          <p>Are you sure you want to {{ jobTitle.toLowerCase() }} this job to Programmatic?</p>
        </div>
        <div v-if="jobAction === jobActionEnum.expire && !renderLoading && !isSuccess" class="modal-body text-sm-75">
          <p>This job will be removed immediately but you may continue to receive clicks, spend, or apps on this job for 72 hours or more.</p>
        </div>
        <div v-if="!renderLoading && !isSuccess" class="modal-footer">
          <button type="button" class="btn btn-link" data-dismiss="modal">
            Cancel
          </button>
          <button v-if="jobAction === jobActionEnum.resume" id="post-button" type="button" class="btn btn-primary" @click.stop="onConfirmJobAction(jobAction)">
            {{ jobTitle }} Job
          </button>
          <button v-if="jobAction === jobActionEnum.expire" id="stop-button" type="button" class="btn btn-primary" @click.stop="onConfirmJobAction(jobAction)">
            {{ jobTitle }} Job
          </button>
        </div>
        <div v-else-if="renderLoading && !isSuccess" class="modal-body">
          <div class="d-flex justify-content-center align-items-center">
            <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div v-else-if="isSuccess && jobAction === 'resume'" class="modal-body">
          <SuccessData :message="postJobMessage" />
        </div>
        <div v-else-if="isSuccess && jobAction === 'expire'" class="modal-body">
          <SuccessData :message="stopJobMessage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpRequest from '../mixins/http-request';
import SuccessData from './SuccessData.vue';

const JobActionEnum = require('../utils/enums/JobActionEnum');

export default {
  name: 'JobActionModal',
  components: {
    SuccessData,
  },
  data() {
    return {
      jobActionEnum: JobActionEnum,
      renderLoading: false,
      isSuccess: false,
      postJobMessage: 'Successfully Posted Job',
      stopJobMessage: 'Successfully Stopped Job',
    }
  },
  props: {
    jobActionData: {
      type: [Object, null],
      required: true
    },
    jobAction: {
      type: String,
      required: true
    }
  },
  mixins: [httpRequest],
  computed: {
    jobTitle() {
      return this.jobAction === JobActionEnum.resume ? 'Post' : 'Stop';
    }
  },
  mounted() {
    $('#action-job-modal').on('hide.bs.modal', this.close);
  },
  methods: {
    close() {
      this.$emit('close');
      this.renderLoading = false;
      this.isSuccess = false;
    },
    async onConfirmJobAction(jobAction) {
      if (!this.jobActionData) return;

      switch (jobAction) {
        case JobActionEnum.resume:
          this.$emit('confirm-post-job', this.jobActionData.job_id);
          break; 
        case JobActionEnum.expire:
          this.$emit('confirm-stop-job', this.jobActionData.job_id);
          break; 
      }

      this.renderLoading = true;

      const url = `${process.env.VUE_APP_PGM_API}/api/campaign/updatejobstatus`;
      const payload = JSON.stringify({
        campaign_id: this.jobActionData.campaign_id,
        cycle_id: this.jobActionData.cycle_id,
        division_id: this.jobActionData.division_id,
        recruiter_id: this.jobActionData.recruiter_id,
        job_id: this.jobActionData.job_id,
        user_name: this.jobActionData.user_name,
        action: this.jobAction,
      });
      
      await this.httpPost(url, payload);

      this.isSuccess = true;

      return;
    }
  }
}
</script>

<style scoped>

</style>
