import App from './App.vue';
import { createApp } from 'vue';
import router from './router';
import store from './store';
import { datadogRum } from '@datadog/browser-rum';

// Start of Datadog RUM Config
datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service:process.env.VUE_APP_DATADOG_SERVICE_NAME,
    env:process.env.VUE_APP_DATADOG_ENV,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel:'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();
// End of Datadog RUM Config

const app = createApp(App).use(router).use(store);

app.config.globalProperties.store = store;

app.mount('#app');