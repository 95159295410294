<template>
  <div :id="title"></div>
  <span class="text-sm-75 border border-5 bg-light-fill p-3 w-100 rounded d-inline-block">
    <span class="text-dark-50 font-weight-bold">Notice:</span>
    Values indicated by an asterisk (*) may experience modifications within three days of the event's reporting date. This period allows for thorough reconciliation of clicks and billing information.
  </span>
</template>

<script>
import httpRequest from '../mixins/http-request';

export default {
  name: 'DailyTrafficChart',
  emits: ['update-sparkline', 'chart-length'],
  props: {
    url: {
      type: String,
      required: true
    },
    totalApps: {
      type: Number,
      required: true
    },
    campaignRatio: {
      type: Number
    },
    chartType: {
      type: Number,
      required: true
    },
  },
  mixins: [httpRequest],
  data() {
    return {
      title: 'daily-traffic-chart',
      chartData: null,
      startDate: [],
      clicks: [],
      apps: [],
      spend: [],
      cpc: [],
      dailyClicksGraph: [0, 0, 0, 0, 0, 0, 0],
      dailyAppsGraph: [0, 0, 0, 0, 0, 0, 0],
      dailyConversionRateGraph: [0, 0, 0, 0, 0, 0, 0],
    }
  },
  computed: {
    ratio() {
      return this.campaignRatio && this.chartType === 0 ? this.campaignRatio : .5;
    }
  },
  watch: {
    url: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        this.destroyChart();

        this.startDate = [];
        this.clicks = [];
        this.apps = [];
        this.spend = [];
        this.cpc = [];
        this.dailyClicksGraph = [0, 0, 0, 0, 0, 0, 0];
        this.dailyAppsGraph = [0, 0, 0, 0, 0, 0, 0];
        this.dailyConversionRateGraph = [0, 0, 0, 0, 0, 0, 0];

        await this.getDailyTrafficData();

        this.$emit('chart-length', this.chartData.length);
        this.addChartData();
        this.getSparklineData();
      },
      immediate: true,
    }
  },
  beforeUnmount() {
    this.destroyChart();
  },
  methods: {
    destroyChart() {
      const chart = $(`#${this.title}`).highcharts();

      if (chart) chart.destroy();
    },
    async getDailyTrafficData() {
      this.chartData = await this.httpGet(this.url);
    },
    displayYAxis() {
      let yAxis = [
          { // Primary yAxis
            title: {
              text: 'Traffic'
            }
          }];
      if (this.chartType === 0) {
        yAxis.push(
          { // 2nd yAxis
            title: {
              text: 'Apps'
            },
          },
          { // 3rd yAxis
            title: {
              text: 'Spend'
            },
            opposite: true
        });
      } else {
        yAxis.push(
          { // 2nd yAxis
            title: {
              text: 'Apps'
            },
            opposite: true
          }
        );
      }

      return yAxis;
    },
    displaySeries() {
      let series = [
          {
            name: 'Traffic',
            yAxis: 0,
            data: this.clicks
          },
          {
            name: 'Apps',
            yAxis: 1,
            data: this.apps,
            visible: this.totalApps > 0 ? true : false
          },
        ]
      if (this.chartType === 0) {
        series.push({
          name: 'Spend',
          yAxis: 2,
          data: this.spend,
          tooltip: {
            valuePrefix: '$ '
          }
        });
      }

      return series;
    },
    addChartData() {
      if (this.chartData.length < 1) return;

      this.startDate = this.chartData[0].date.split('-');

      this.chartData.forEach(data => {
        const chartRatio = data.ratio ? data.ratio : this.ratio;
        const jobDate = data.date.split('-');

        this.clicks.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), data.clicks]);
        this.apps.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), data.apps]);
        this.spend.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), parseFloat((data.spend / chartRatio).toFixed(2))]);
        this.cpc.push([Date.UTC(jobDate[0], jobDate[1] - 1, jobDate[2]), data.cpc]);
        this.dailyClicksGraph[data.day_of_week - 1] += data.clicks;
        this.dailyAppsGraph[data.day_of_week - 1] += data.apps;
      });

      this.displayHighChart();
    },
    getSparklineData() {
      if (this.chartData.length < 2) return;

      for (var i = 0; i < 7; i++) {
        if (this.dailyClicksGraph == 0 || this.dailyAppsGraph == 0) {
          this.dailyConversionRateGraph[i] = '0.00';
          continue;
        }

        this.dailyConversionRateGraph[i] = (this.dailyAppsGraph[i] / this.dailyClicksGraph[i]) * 100
      }

      this.$emit('update-sparkline', {
        clicks: this.dailyClicksGraph,
        apps: this.dailyAppsGraph,
        conversion: this.dailyConversionRateGraph,
      });
    },
    displayHighChart() {
      const self = this;

      Highcharts.chart(self.title, {
        chart: {
          type: 'spline',
          zoomType: 'x'
        },
        title: {
          text: 'Daily Traffic *',
          style: {
            color: '#212529',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '24px'
          }
        },
        subtitle: {
          // document.ontouchstart === undefined ? subTitle[0] : subTitle[1]
          text: 'Click and drag in the plot area to zoom in',
          style: {
            color: '#343A40',
            fontSize: '12px',
            lineHeight: '18px'
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          title: {
              text: 'Date'
          },
          labels: {
            formatter() {
              return Highcharts.dateFormat('%b %e', this.value);
            }
          }
        },
        yAxis: self.displayYAxis(),
        tooltip: {
          shared: true
        },
        plotOptions: {
          spline: {
            lineWidth: 4,
            states: {
              hover: {
                lineWidth: 5
              }
            },
            marker: {
              enabled: false
            },
            pointInterval: 24 * 3600 * 1000,
            pointStart: Date.UTC(self.startDate[0], self.startDate[1] - 1, self.startDate[2])
          }
        },
        series: self.displaySeries(),
        navigation: {
          menuItemStyle: {
            fontSize: '10px'
          }
        },
        exporting: {
          csv: {
            dateFormat: '%Y-%m-%d'
          },
          buttons: {
            contextButton: {
              menuItems: [
                'viewFullscreen',
                'printChart',
                'separator',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG',
                'downloadCSV',
                'downloadXLS',
                'viewData'
              ]
            }
          }
        }
      });
    }
  }
}
</script>

<style scoped>
#daily-traffic-chart {
  width: 100%;
}
</style>