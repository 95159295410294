import { reactive, readonly } from 'vue';

const state = reactive({
  navBarData: {}
});

const currentCycleId = reactive({
  cycleId: null
})

const methods = {
  getNavBarData() {
    return state.navBarData;
  },

  setNavBarData(state, payload) {
    state.navBarData = payload;
  },

  getJtToken() {
    return state.navBarData.jtToken;
  },

  setCurrentCycleId(id) {
    currentCycleId.cycleId = id;
  },

  getCurrentCycleId(){
    return currentCycleId.cycleId;
  }
};

export default {
  state: state,
  methods,
};