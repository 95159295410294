<template>
  <div class="row mr-0 ml-0 mb-0">
    <AnalyticsCard
      v-for="data in analyticsData"
      :key="data.name"
      :class="data.className"
      :clickableTileReqs="clickableTileReqs"
      :isClickableTile="data.isClickableTile"
    >
      <template v-slot:title>
        {{ data.name }}
      </template>
      <template v-slot:value>
        {{ data.value }}
      </template>
      <template v-if="data.graphData" v-slot:value2>
        <SparklineChart :name="alterName(data.name.toLowerCase())" :seriesData="data.graphData" />
      </template>
      <template v-else-if="data.value2" v-slot:value2>
        {{ data.value2 }}
      </template>
    </AnalyticsCard>
  </div>
</template>

<script>
import AnalyticsCard from './AnalyticsCard.vue';
import SparklineChart from './SparklineChart.vue';

export default {
  name: 'AnalyticsCards',
  components: {
    AnalyticsCard,
    SparklineChart
  },
  props: {
    analyticsData: {
      type: Array,
      required: true
    },
    clickableTileReqs: {
      type: [Object, null],
      required: true
    }
  },
  methods: {
    alterName(name) {
      return name.replace(' ', '-');
    }
  }
};
</script>

<style scoped>
.analytics-card {
  text-align: center;
  height: 150px;
  color: #E4E6EF;
}

.card-all-border {
  border: 1px solid;
}

.card-top-left-border {
  border-top: 1px solid;
  border-left: 1px solid;
}

.card-no-right-border {
  border: 1px solid;
  border-right: none;
}

.card-no-bottom-border {
  border: 1px solid;
  border-bottom: none;
}

.card-left-radius {
  border-radius: 4px 0 0 4px;
}

.card-right-radius {
  border-radius: 0 4px 4px 0;
}

.card-top-left-radius {
  border-top-left-radius: 4px;
}

.card-top-right-radius {
  border-top-right-radius: 4px;
}

.card-bottom-left-radius {
  border-bottom-left-radius: 4px;
}

.card-bottom-right-radius {
  border-bottom-right-radius: 4px;
}

.clickable-tile:hover {
  background-color: #EAF3FC;
  cursor: pointer;
}
</style>