<template>
  <div class="card-header bg-transparent">
    <div class="row">
      <!-- Icon and Title -->
      <div class="col-sm-6 d-flex align-items-center">
        <div class="app__logo mr-3">
          <!-- App Logo -->
          <img
            src="https://res.cloudinary.com/job-target/image/upload/v1600452940/Wordpress/Programmatic_11688a510.svg"
            alt="Programmatic"
            width="40"
            height="40"
          />
        </div>
        <!-- App Title -->
        <h2 id="header" class="mb-sm-0 h5">
          <span v-if="productTier === productTiersEnum.programmaticPlus || productTier === productTiersEnum.programmaticPlusPostPay">
            Programmatic
            <span class="custom-tier align-middle text-sm-75 px-2 font-weight-bold rounded-sm text-light">+</span>
          </span>
          <span v-else-if="productTier === productTiersEnum.programmatic360 || productTier === productTiersEnum.programmatic360PostPay">
            Programmatic
            <span class="custom-tier align-middle text-sm-75 px-1 font-weight-bold rounded-sm text-light">360</span>
          </span>
          <span v-else>Programmatic</span>
        </h2>
      </div>
      <div v-if="pageloadData" class="col-sm-6 d-flex justify-content-sm-end align-items-center">
        <a v-if="pageloadData.page === 3" class="btn btn-primary ml-2" href="#" onclick="history.back()">Go Back</a>
        <a v-if="redirectCampaign" class="btn btn-primary ml-2" :href="campaignUrl">Campaign Dashboard</a>
        <a v-if="redirectSingles" class="btn btn-primary ml-2" :href="singlesUrl">Singles Dashboard</a>
        <button v-if="pageloadData.page === 0"
          id="contactRMButton"
          class="fa-stack btn btn-outline-primary ml-2"
          title='Contact your Relationship Manager'
          data-container="body" 
          data-tooltip="tooltip"
          href="#"
          data-toggle="modal"
          data-target="#contact-relationship-manager-modal"
        >
          <i class="envelope-icon fal fa-envelope-open-text fa-stack-2x"></i>
          <i class="envelope-icon fal fa-envelope-open-text fa-stack-2x"></i>
        </button>
        <!-- header type 0 is for campaigns, singles, select-jobs -->
        <MoreDropdown v-if="headerType === 0" :pageloadData="pageloadData" :cycle="cycle" :renderUpdateBudget="renderUpdateBudget" :renderDropdown="renderDropdown"/>
        <!-- header type 1 is for scheduled navbar page -->
        <ScheduledNavbar v-else-if="headerType === 1" :activeTab="activeTab" @change-tab="$emit('change-tab', $event)" />

        <UpdateBudgetModal v-if="renderUpdateBudget" 
          :pageloadData="pageloadData" 
          :cycle="cycle"
          :campaignId="campaignId" 
          :currentCycle="currentCycle" 
          :campaignContacts="campaignContacts"
        />
      </div>
    </div>
  </div>
  <ContactRelationshipManagerModal  :pageloadData="pageloadData" :campaignContacts="campaignContacts" :campaignId="campaignId"/>
</template>

<script>
import MoreDropdown from './MoreDropdown.vue';
import ScheduledNavbar from './ScheduledNavbar.vue';
import UpdateBudgetModal from './UpdateBudgetModal.vue';
// import BudgetAllocationModal from './BudgetAllocationModal.vue'; Budget Allocation Removal
import ContactRelationshipManagerModal from './ContactRelationshipManagerModal.vue';

const ProductTiersEnum = require('../utils/enums/ProductTiersEnum');

export default {
  name: 'Header',
  emits: ['update-budget-success', 'change-tab'],
  components: {
    MoreDropdown,
    UpdateBudgetModal,
    ScheduledNavbar,
    // BudgetAllocationModal, Budget Allocation Removal
    ContactRelationshipManagerModal
  },
  props: {
    pageloadData: {
      type: Object,
    },
    renderUpdateBudget: {
      type: Boolean
    },
    // renderBudgetAllocation: { Budget Allocation Removal
    //   type: Boolean
    // },
    cycle: {
      type: Object
    },
    currentCycle: {
      type: Object
    },
    headerType: {
      type: Number,
      required: true
    },
    campaignId: {
      type: String
    },
    campaignContacts: {
      type: Object
    },
  },
  data() {
    return {
      productTiersEnum: ProductTiersEnum,
      activeTab: 'overview',
      campaignUrl: `${process.env.VUE_APP_PGM_UI}/dashboard`,
      singlesUrl: `${process.env.VUE_APP_PGM_UI}/singles`,
      redirectCampaign: false,
      redirectSingles: false,
      renderDropdown: false
    }
  },
  computed: {
    productTier() {
      return this.pageloadData ? this.pageloadData.product_tier : '';
    },
  },
  watch: {
    pageloadData: {
      handler(newVal, oldVal) {
        if (!newVal) return;

        this.renderDropdown = true;

        if (newVal.page === 0) {
          this.redirectSingles = newVal.singles;
        } else if (newVal.page === 1) {
          this.redirectCampaign = newVal.campaign;
        }
      },
      immediate: true,
      deep: true,
    }
  },
}
</script>

<style scoped>
#contactRMButton {
  padding: 18px 18px;
}
.envelope-icon {
  line-height: 0;
  font-size: 1.6em;
}
.custom-tier {
    background-color: #E0B869;
  }
</style>
