<template>
  <div class="modal fade" id="add-app-modal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add App</h5>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close" @click="hideModal">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <SuccessData :message="message" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessData from './SuccessData.vue'; 

export default {
  name: 'AddAppModal',
  components: {
    SuccessData,
  },
  data() {
    return {
      message: 'Thank you for your interest. A representative will reach out to you shortly.',
    }
  },
  mounted() {
    $('#add-app-modal').on('hide.bs.modal', this.close);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    hideModal() {
      $('#add-app-modal').modal('hide');
    }
  }
}
</script>

<style scoped>
</style>