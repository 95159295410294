<template>
  <div id="jt-programmatic" class="container mt-4 mb-4">
    <div class="row">
      <div class="col-12">
        <div id="jt-programmatic-card" class="card border-0 w-100">
          <Header :pageloadData="pageloadData" :headerType="1" @change-tab="handleTabChange" />
          <!-- BEGIN: Content -->
          <div v-if="renderData" id="jt-programmatic-body" class="card-body">
            <div class="row d-flex justify-content-center">
              <h5>{{ subTitle }}</h5>
            </div>
            <div class="row d-flex justify-content-center">
              <button v-if="!pageloadData.has_open_division || !pageloadData.has_open_jobs"
                class="btn btn-primary btn-lg align-items-center launch-add-app"
                @click="launchAddApp"
              >
                Get Started
              </button>
              <button v-else
                class="btn btn-primary btn-lg align-items-center"
                @click="redirectSelectJobs"
              >
                Select Jobs For Campaign
              </button>
            </div>
            <hr>
            <div id="scheduled-tab-content" class="tab-content">
              <div id="overview" class="tab-pane fade" :class="{ 'active show': activeTab === 'overview' }">
                <h4 class="scheduled-title">Overview:</h4>
                <p class="scheduled-desc" v-html="overviewDescription"></p>
                <div class="mx-auto text-center">
                  <img :src="programmaticPuzzleImg" alt="programmatic" />
                </div>
              </div>
              <div id="features" class="tab-pane fade" :class="{ 'active show': activeTab === 'features' }">
                <h4 class="scheduled-title">Features</h4>
                <p class="scheduled-desc" v-html="featuresDescription"></p>
              </div>
              <div id="faq" class="tab-pane fade" :class="{ 'active show': activeTab === 'faq' }">
                <h4 class="scheduled-title">FAQ</h4>
                <p class="scheduled-desc" v-html="faqDescription"></p>
              </div>
            </div>
            <hr>
            <div class="row d-flex justify-content-center">
              <button
                class="btn btn-primary btn-lg align-items-center launch-add-app"
                @click="launchAddApp"
              >
                Get Started
              </button>
            </div>
            <AddAppModal v-show="renderApp" @close="closeJobDescModal" />
          </div>
          <!-- END: Content -->
          <div v-else id="jt-programmatic-body" class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary h-6 w-6 text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue';
import AddAppModal from '../../components/AddAppModal.vue';
import store from '../../store/index';
import httpRequest from '../../mixins/http-request';

export default {
  name: 'Scheduled',
  components: {
    Header,
    AddAppModal,
  },
  mixins: [httpRequest],
  data() {
    return {
      activeTab: 'overview',
      userState: store.state,
      router: this.$router,
      renderData: false,
      renderApp: false,
      pageloadData: null,
      subTitle: 'Fully-automated, data-driven, algorithm-managed job postings. The future of recruitment advertising is here!',
      overviewDescription: "Let our algorithms do the work for you. No one has time to manage multiple job advertising campaigns simultaneously. Most recruiters also don’t have the data to understand which jobs should be advertised on which job sites.",
      featuresDescription: "Automate the decision-making process of posting jobs and optimize job ad performance.<br>&nbsp; <br>\r\nAppear in the right job searches to ensure high quality traffic.<br>&nbsp; <br>\r\nGet exposure across the web’s leading job sites - niche sites and social sites as well.<br>&nbsp; <br>\r\nJobs are automatically posted and will remain open until filled (or you take them down).<br>&nbsp; <br>\r\nReduce cost per applicant by eliminating wasteful spend from over bidding or under-performing job sites.<br>&nbsp; <br>\r\nManage recruitment efforts more efficiently by removing administrative busywork.<br>&nbsp; <br>\r\nProgrammatic uses deep historical analytics from millions of postings to deliver your jobs to the most relevant sites that are more likely to deliver quality applicants. Our intelligent algorithms and predictive analytics constantly learn from every job posting and automatically optimize campaign performance in real-time, so your ads are always being placed in front of qualified candidates. As the recruitment marketing landscape evolves, so does Programmatic.",
      faqDescription: "<strong>What is Programmatic?</strong>  Auto-Optimized job postings, also referred to as Programmatic, is our system that automatically and actively manages your job posting campaign for you. It analyzes each job posting, seeing if where it’s posted is driving the right candidates. If it determines a posting isn’t performing well, it shifts budgets to other sites that will. Our clients love this service as it frees them from the manual work of having to analyze and manage each of their postings.\r\n<br>&nbsp; <br>\r\n<strong>How will I know which sites to use?</strong>  Programmatic makes smart buying decisions for you to attract the candidates you need. Our intelligence, from over 15 years in business and over 100 million job postings, makes recommendations based upon the kind of role you are looking to fill. In addition, our team is always available to provide support and help you craft your recruiting strategy.\r\n<br>&nbsp; <br>\r\n<strong>Can I pick and choose which jobs go to Programmatic?</strong>  Yes, we can connect to your career site and automatically pull in all of your jobs or a subset of jobs of your choosing via a Job Importer.  Don’t have a career site? We can help with that too.\r\n<br>&nbsp; <br>\r\n<strong>Can I review my Programmatic campaign results?</strong>  Yes, your campaign will provide access to a Programmatic dashboard. Your dashboard will deliver valuable insight into your ROI and other recruitment advertising benchmarks such as cost per click, cost per application, conversion rate, etc.  These performance metrics are also broken down and the job level and allow you to take action when needed. ",
      programmaticPuzzleImg: require('../../assets/img/programmatic-puzzle.png'),
    }
  },
  watch: {
    userState: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        this.renderData = false;

        await this.pageload();

        this.renderData = true;

        // redirect to error page if page load is null
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleTabChange(tabName) {
      this.activeTab = tabName;
    },
    redirectSelectJobs() {
      return this.router.replace({ path: '/select-jobs' });
    },
    async launchAddApp() {
      const queryParams = `user=${this.userState.navBarData.user_id}&jtToken=${store.methods.getJtToken()}`;
      const sendScheduledEmailUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/sendScheduledEmail?${queryParams}`;
      const notifySalesforceUrl = `${process.env.VUE_APP_PGM_API}/api/campaign/notifySalesforce?${queryParams}`;
      
      await this.httpGet(sendScheduledEmailUrl);
      this.httpGet(notifySalesforceUrl)
      
      $("#add-app-modal").modal('toggle');
    },
    closeJobDescModal() {
      const btn = document.querySelectorAll('.launch-add-app');
      btn.forEach(data => data.classList.add('invisible'));
      this.renderApp = false;
    },
    async pageload() {
      const url = `${process.env.VUE_APP_PGM_API}/api/pageload/scheduled?user_id=${this.userState.navBarData.user_id}`;

      this.pageloadData = await this.httpGet(url);
    }
  }
}
</script>

<style scoped>
.scheduled-title {
  margin-left: 2em;
}
.scheduled-desc {
  margin: 0 3em;
}
</style>
