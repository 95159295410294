<template>
  <div class="modal fade" id="repost-job-modal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Repost Job</h5>
          <button type="button" class="close opacity-10" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="isConfirm" class="col-sm-12 pl-0 pr-0">
            <div class="
              alert alert-warning
              d-flex
              align-items-center
              justify-content-between
            ">
              <i class="fal fa-exclamation-triangle text-2"></i>
          
              <p class="mb-0 w-100 pl-4">Your order is being processed. You will be redirected to the checkout page in a moment. Please do not refresh or close your browser</p>
              <button type="button" class="btn btn-transparent text-warning close" data-dismiss="alert">
                <i class="fal fa-times text-2"></i>
              </button>
            </div>
          </div>
            <p class="pl-1">Your job will be reposted to Programmatic. You will be landed on the checkout page to complete your order.</p>
        </div>
        <div class="modal-footer">
          <button v-if="isCancel" type="button" class="btn btn-link" data-dismiss="modal">
            Cancel
          </button>
          <button id="checkout-button" type="button" class="btn btn-primary" @click.stop="onConfirmRepost">
            {{ checkoutButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpRequest from '../mixins/http-request';

export default {
  name: 'RepostModal',
  props: {
    repostData: {
      type: [Object, null],
      required: true
    }
  },
  data() {
    return {
      checkoutButtonText: 'Continue to Checkout',
      isConfirm: false,
      isCancel: true,
    }
  },
  mixins: [httpRequest],
  mounted() {
    $('#repost-job-modal').on('hide.bs.modal', this.close);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async onConfirmRepost() {
      if (!this.repostData) return;
      this.checkoutButtonText = 'Loading...';
      this.isCancel = false;
      this.isConfirm = true;

      const url = `${process.env.VUE_APP_PGM_API}/api/single/createrepostorder?recruiter_id=${this.repostData.recruiter_id}&division_id=${this.repostData.division_id}&job_id=${this.repostData.job_id}`;

      const result = await this.httpGet(url);

      if (result.payuilink) {
        window.location.href = `${result.payuilink}?app=platform`;
      } else {
        console.log('Error occured when confirming repost job');
      }
    }
  },
};
</script>

<style scoped>

</style>